<template>
    <Icon
        name="gg:spinner"
        class="animate-spin"
        :size="size" />
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
    size?: 'sm' | 'md' | 'lg' | 'xl'
}>(), {
    size: 'sm'
})

const sizeMap = new Map(
    [
        ['sm', '1.25em'],
        ['md', '2em'],
        ['lg', '4em'],
        ['xl', '8em']
    ]
)

const size = computed(() => {
    return sizeMap.get(props.size)
})

</script>